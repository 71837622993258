body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#root {
  min-height: 99vh;
}

.removeInputBorder .MuiInput-underline:before {
  border: none !important;
}

.removeInputBorder .MuiAutocomplete-inputRoot,
.removeInputBorder .MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}

::-webkit-scrollbar {
  width: 0.8rem;
  background-color: #f4f3f3;
}

::-webkit-scrollbar-thumb {
  background-color: #97877a;
}

.divergenciaMaxWidth {
  width: 672px !important;
  max-width: 672px !important;
  box-sizing: border-box !important;
}

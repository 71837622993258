.neutral800 {
  color: #3a3a3a !important;
}

.neutral700 {
  color: #565656 !important;
}

.neutral500 {
  color: #949394 !important;
}

.neutral400 {
  color: #b5b5b5 !important;
}

.neutral300 {
  color: #dcdbdc !important;
}

.neutral100 {
  color: #f4f3f3 !important;
}

.neutral000 {
  color: #fff !important;
}

.primary500 {
  color: #97877a !important;
}

.primary100 {
  color: #f4f2f1 !important;
}

.backgroundNeutral00 {
  background: #fff;
}

.backgroundPrimary500 {
  background: #97877a !important;
}

.backgroundNeutral50 {
  background: #f9f9f9 !important;
}

.iconPrimary100 path {
  stroke: #f4f2f1;
}

.iconPrimary500 path {
  stroke: #97877a;
}

.iconNeutral300 path {
  stroke: #dcdbdc;
}

.iconNeutral500 path {
  stroke: #949394;
}

.iconNeutral800 path {
  stroke: #3a3a3a;
}

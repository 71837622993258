.tableHeader {
  background: #f4f3f3;
  border-radius: 4px;
}

tbody {
  background: #f9f9f9 !important;
}

table {
  border-color: #f9f9f9 !important;
  border: none !important;
}

.tableRowHover:hover {
  background: white;
  box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  transition: all 150ms ease-in;
}

.divider {
  height: 24px;
  border-left: 2px solid #f4f3f3;
}

.pointer {
  cursor: pointer;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-transform-initial {
  text-transform: initial;
}

.uppercase {
  text-transform: uppercase;
}

.d-flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.flexGrow1 {
  flex-grow: 1;
}

.d-inline-flex {
  display: inline-flex;
}

.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.mentionsInput textarea:focus {
  outline: none;
}

.input-box {
  outline: 0;
  border-width: 0 0 2px;
  border-color: black;
  background: transparent;
}

.input-box:focus {
  border-color: green
}

.minHeightDivergenciaModalContent {
  min-height: none;
}

.fillHeightWithTopbar {
  min-height: calc(100vh - 84px);
}

@media only screen and (min-height: 900px) {
  .minHeightDivergenciaModalContent {
    min-height: 616px;
  }
}

@media only screen and (min-height: 975px) {
  .minHeightDivergenciaModalContent {
    min-height: 692px;
  }
}

@media only screen and (min-height: 1050px) {
  .minHeightDivergenciaModalContent {
    min-height: 766px;
  }
}

@media only screen and (min-height: 1080px) {
  .minHeightDivergenciaModalContent {
    min-height: 795px;
  }
}
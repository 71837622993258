@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.headline {
  font-family: Inter !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.0025em !important;
  line-height: 48px !important;
}

.h6.headline {
  font-size: 20px !important;
}

.h5.headline {
  font-size: 24px !important;
}

.h4.headline {
  font-size: 34px !important;
}

.caption {
  font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  letter-spacing: 0.004em !important;
  line-height: 15px !important;
}

.body1 {
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.005em !important;
  line-height: 24px !important;
}

.body2 {
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.0025em !important;
  line-height: 24px !important;
}

.subtitle1 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0015em;
  line-height: 24px;
}

.overline {
  font-family: Inter !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.02em !important;
  line-height: 12px !important;
}

.button {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.0125em;
  line-height: 24px;
  text-transform: initial;
}

.medium {
  font-weight: 500 !important;
}

.bold {
  font-weight: bold !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.text-center {
  text-align: center;
}

.lineHeight28 {
  line-height: 38px !important;
}

/* ### RIGHT ### */
.mr16 {
  margin-right: 16px !important;
}

/* ### LEFT ### */
.mlAuto {
  margin-left: auto !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.ml22 {
  margin-left: 22px !important;
}

/* ### TOP ### */
.mtAuto {
  margin-top: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt32 {
  margin-top: 32px !important;
}

/* ### BOTTOM ### */
.mb8 {
  margin-bottom: 8px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

/* ### x ### */
.mx8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.activeRoute {
  color: #3a3a3a !important;
  border-bottom: 2px solid #97877a;
  font-weight: 600 !important;
}

.errorPage {
  height: 100%;
  min-height: inherit;
}

.errorPage .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  max-width: 700px;
  min-height: inherit;
  width: 75%;
}

@media (max-width: 650px) {
  .errorPage .container {
    width: 85%;
  }
}

.errorPage .compcontainer {
  height: 13rem;
  padding: 1rem 0;
  width: 75%;
}

@media (max-width: 650px) {
  .errorPage .compcontainer {
    height: 10rem;
  }
}

.errorPage .compcontainer svg {
  -webkit-animation: bouncy 1300ms linear infinite;
          animation: bouncy 1300ms linear infinite;
  max-height: 100%;
  max-width: 100%;
}

.errorPage .instructions {
  background: #fefefe;
  border-radius: 0.25rem;
  border: 1px solid #dcdcdc;
  height: auto;
  padding: 1rem;
  width: 80%;
}

@media (max-width: 650px) {
  .errorPage .instructions {
    width: 100%;
  }
}

.errorPage .instructions h2 {
  color: #e30528;
  font-size: 1.25em;
  line-height: 1.3;
}

@media (max-width: 650px) {
  .errorPage .instructions h2 {
    font-size: 1.05em;
  }
}

.errorPage .instructions span {
  color: #122125;
  font-size: 1.15em;
  line-height: 1.5;
}

@media (max-width: 650px) {
  .errorPage .instructions span {
    font-size: 1em;
  }
}

.errorPage .instructions .step {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  margin: 0.5rem 0;
  width: 100%;
}

.errorPage .instructions .step .icon {
  align-self: center;
  height: 1.25rem;
  width: 1.25rem;
}

@media (max-width: 650px) {
  .errorPage .instructions .step .icon {
    height: 1rem;
    width: 1rem;
  }
}

.errorPage .instructions .step span {
  display: inline-block;
  line-height: 1.5;
  padding-left: 0.5rem;
  width: 80%;
}

@-webkit-keyframes bouncy {
  0% {
    transform: translateY(10px) translateX(0) rotate(0);
  }

  25% {
    transform: translateX(-10px) rotate(-10deg);
  }

  50% {
    transform: translateX(0) rotate(0deg);
  }

  75% {
    transform: translateX(10px) rotate(10deg);
  }

  100% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
}

@keyframes bouncy {
  0% {
    transform: translateY(10px) translateX(0) rotate(0);
  }

  25% {
    transform: translateX(-10px) rotate(-10deg);
  }

  50% {
    transform: translateX(0) rotate(0deg);
  }

  75% {
    transform: translateX(10px) rotate(10deg);
  }

  100% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#root {
  min-height: 99vh;
}

.removeInputBorder .MuiInput-underline:before {
  border: none !important;
}

.removeInputBorder .MuiAutocomplete-inputRoot,
.removeInputBorder .MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}

::-webkit-scrollbar {
  width: 0.8rem;
  background-color: #f4f3f3;
}

::-webkit-scrollbar-thumb {
  background-color: #97877a;
}

.divergenciaMaxWidth {
  width: 672px !important;
  max-width: 672px !important;
  box-sizing: border-box !important;
}

.neutral800 {
  color: #3a3a3a !important;
}

.neutral700 {
  color: #565656 !important;
}

.neutral500 {
  color: #949394 !important;
}

.neutral400 {
  color: #b5b5b5 !important;
}

.neutral300 {
  color: #dcdbdc !important;
}

.neutral100 {
  color: #f4f3f3 !important;
}

.neutral000 {
  color: #fff !important;
}

.primary500 {
  color: #97877a !important;
}

.primary100 {
  color: #f4f2f1 !important;
}

.backgroundNeutral00 {
  background: #fff;
}

.backgroundPrimary500 {
  background: #97877a !important;
}

.backgroundNeutral50 {
  background: #f9f9f9 !important;
}

.iconPrimary100 path {
  stroke: #f4f2f1;
}

.iconPrimary500 path {
  stroke: #97877a;
}

.iconNeutral300 path {
  stroke: #dcdbdc;
}

.iconNeutral500 path {
  stroke: #949394;
}

.iconNeutral800 path {
  stroke: #3a3a3a;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.headline {
  font-family: Inter !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.0025em !important;
  line-height: 48px !important;
}

.h6.headline {
  font-size: 20px !important;
}

.h5.headline {
  font-size: 24px !important;
}

.h4.headline {
  font-size: 34px !important;
}

.caption {
  font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  letter-spacing: 0.004em !important;
  line-height: 15px !important;
}

.body1 {
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.005em !important;
  line-height: 24px !important;
}

.body2 {
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.0025em !important;
  line-height: 24px !important;
}

.subtitle1 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0015em;
  line-height: 24px;
}

.overline {
  font-family: Inter !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 0.02em !important;
  line-height: 12px !important;
}

.button {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.0125em;
  line-height: 24px;
  text-transform: none;
  text-transform: initial;
}

.medium {
  font-weight: 500 !important;
}

.bold {
  font-weight: bold !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.text-center {
  text-align: center;
}

.lineHeight28 {
  line-height: 38px !important;
}

/* ### RIGHT ### */
.mr16 {
  margin-right: 16px !important;
}

/* ### LEFT ### */
.mlAuto {
  margin-left: auto !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.ml22 {
  margin-left: 22px !important;
}

/* ### TOP ### */
.mtAuto {
  margin-top: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt32 {
  margin-top: 32px !important;
}

/* ### BOTTOM ### */
.mb8 {
  margin-bottom: 8px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

/* ### x ### */
.mx8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.pa16 {
  padding: 16px !important;
}
.px16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.tableHeader {
  background: #f4f3f3;
  border-radius: 4px;
}

tbody {
  background: #f9f9f9 !important;
}

table {
  border-color: #f9f9f9 !important;
  border: none !important;
}

.tableRowHover:hover {
  background: white;
  box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  transition: all 150ms ease-in;
}

.divider {
  height: 24px;
  border-left: 2px solid #f4f3f3;
}

.pointer {
  cursor: pointer;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-transform-initial {
  text-transform: none;
  text-transform: initial;
}

.uppercase {
  text-transform: uppercase;
}

.d-flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.flexGrow1 {
  flex-grow: 1;
}

.d-inline-flex {
  display: inline-flex;
}

.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.mentionsInput textarea:focus {
  outline: none;
}

.input-box {
  outline: 0;
  border-width: 0 0 2px;
  border-color: black;
  background: transparent;
}

.input-box:focus {
  border-color: green
}

.minHeightDivergenciaModalContent {
  min-height: none;
}

.fillHeightWithTopbar {
  min-height: calc(100vh - 84px);
}

@media only screen and (min-height: 900px) {
  .minHeightDivergenciaModalContent {
    min-height: 616px;
  }
}

@media only screen and (min-height: 975px) {
  .minHeightDivergenciaModalContent {
    min-height: 692px;
  }
}

@media only screen and (min-height: 1050px) {
  .minHeightDivergenciaModalContent {
    min-height: 766px;
  }
}

@media only screen and (min-height: 1080px) {
  .minHeightDivergenciaModalContent {
    min-height: 795px;
  }
}
